// 获取报告
import request from "@/utils/request";

export function reports(params) {
    return request({
        url: '/api/h5/reports',
        method: 'get',
        params
    })
}
export function reportsRead(data) {
    return request({
        url: '/api/report/read',
        method: 'PUT',
        data
    })
}
