<template>
  <div class="file_main">
    <div class="file_nothing" v-if="list.length !== 0">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="item in list" :key="item.report_id" class="list"
             @click="open(item.file_url,item.name,item.report_id)">
          <span class="list_title">{{ item.name }} <i v-if="!item.is_read"></i></span>
          <span class="list_time">{{ item.create_time }}</span>
        </div>
      </van-list>
    </div>
    <div class="file_have" v-else>
      <img src="../../assets/images/mobile_nothing_file.svg" alt="">
      <h3>暂无您的报告数据</h3>
      <p>请联系您的理财师为您生成最新报告</p>
    </div>
  </div>
</template>

<script>
import {reports, reportsRead} from '@/api/file'

export default {
  name: "file",
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
    };
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    onLoad() {
      const phone = this.$store.state.user.phone
      reports({phone: phone}).then(res => {
        this.list = res.data
        this.loading = false;
        this.finished = true;
      })
    },

    open(url, name, report_id) {
      const obj = this.list.find((obj) => {
        return obj.report_id === report_id;
      });
      obj.is_read = true
      //调取接口
      const info = {
        location: 'client',
        report_id: report_id
      }
      reportsRead(info).then(() => {

      })

      //点击下载按钮判断
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      if (isiOS) {
        window.open(url)
      } else if (isAndroid) {
        this.$router.push({name: 'openPdf', query: {url: url, name: 'android'}})
      }

    },
  },
}
</script>

<style scoped lang="scss">
.file_main {
  //margin-top: 12px;

  .file_have {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    img {
      width: 163px;
      height: 161px;
      margin-bottom: 24px;
    }

    h3 {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D1D1D;
      line-height: 22px;
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A0A3A9;
      line-height: 20px;
    }
  }

  .list {
    width: 100%;
    height: 88px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 16px 20px;
    background: #FFFFFF;
    margin-bottom: 8px;

    &:first-child {
      margin-top: 12px;
    }

    .list_title {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D1D1D;
      line-height: 21px;

      i {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #E02020;
        border-radius: 6px;
      }
    }

    .list_time {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A0A3A9;
      line-height: 21px;
    }
  }
}
</style>
